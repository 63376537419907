<template>
  <div class="pb-12 home-container">
    <div v-if="hasCMSPermission">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_cms">websites</h2>
      <div class="bookmarks-wrapper">
        <div
          class="bookmark bookmark_cms"
          @click="go('websites.select-property')"
          v-if="!hasWebsite"
        >
          <div class="bookmark--title">add new website</div>
          <div class="bookmark--description">
            Add a new website for an existing or current property
          </div>
        </div>
        <div
          class="bookmark bookmark_cms"
          @click="go('websites.show', { id: websiteIdbyCommunity })"
          v-if="hasWebsite"
        >
          <div class="bookmark--title">manage website</div>
          <div class="bookmark--description">
            Manage an existing website that has already been setup
          </div>
        </div>
          <div
            class="bookmark bookmark_cms"
            v-if="hasWebsite"
          >
            <div class="bookmark--title">website settings</div>
            <div class="bookmark--description">
              Manage website settings
            </div>
          </div>
      </div>
    </div>
    <div v-if="hasMarketingPermission" class="mt-12">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_mg">marketing</h2>
      <div class="bookmarks-wrapper">

        <div class="bookmark bookmark_mg" @click="go('medias.index')">
          <div class="bookmark--title">media gallery</div>
          <div class="bookmark--description">
            view and manage all existing images that have been added
          </div>
        </div>     
        <div class="bookmark bookmark_mg" @click="go('guest-cards.index')">
          <div class="bookmark--title">guest cards</div>
          <div class="bookmark--description">
            create, view and manage guest card
          </div>
        </div>

        <div class="bookmark bookmark_mg" @click="go('calendar_events.index')">
          <div class="bookmark--title">Calendar</div>
          <div class="bookmark--description">
            Shows calendar events
          </div>
        </div>        
      </div>
    </div>
    <div v-if="hasDHPermission" class="mt-12">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_dh">digital human</h2>
      <div class="bookmarks-wrapper">
        <Authorize :has-role="['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER']">
          <div class="bookmark bookmark_dh" @click="go('responses.index')">
            <div class="bookmark--title">global response catalog</div>
            <div class="bookmark--description">
              manage the catalog of global responses
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER']">
          <div
            class="bookmark bookmark_dh"
            @click="go('custom_responses.index')"
          >
            <div class="bookmark--title">monitor custom responses</div>
            <div class="bookmark--description">
              manage the catalog of custom responses
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER']">
          <div
            class="bookmark bookmark_dh"
            @click="go('translate_responses.index')"
          >
            <div class="bookmark--title">translation tools</div>
            <div class="bookmark--description">
              translate global and custom responses into spanish
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN', 'CUSTOMER_ADMIN', 'USER']">
          <div
            class="bookmark bookmark_dh"
            @click="go('availability_pricing.index')"
          >
            <div class="bookmark--title">unit availability and pricing</div>
            <div class="bookmark--description">
              control which units appear in the list when prospects ask the
              digital human about availability
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN', 'CUSTOMER_ADMIN', 'USER']">
          <div
            class="bookmark bookmark_dh"
            @click="go('conversations_responses.index')"
          >
            <div class="bookmark--title">customize your conversation</div>
            <div class="bookmark--description">
              determine how your digital human will respond for each
              conversation topic
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN', 'CUSTOMER_ADMIN', 'USER']">
          <div
            class="bookmark bookmark_dh"
            @click="go('conversation_dashboards.index')"
          >
            <div class="bookmark--title">conversation dashboards</div>
            <div class="bookmark--description">
              insights about conversations your prospects have with digital
              humans
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN', 'CUSTOMER_ADMIN', 'USER']">
          <div
            class="bookmark bookmark_dh"
            @click="go('digital_human_settings.index')"
          >
            <div class="bookmark--title">digital human settings</div>
            <div class="bookmark--description">
              set up important information about your community
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN', 'CUSTOMER_ADMIN', 'USER']">
          <div
            class="bookmark bookmark_dh"
            @click="go('guest_contacts.index')"
          >
            <div class="bookmark--title">contacts</div>
            <div class="bookmark--description">
              review guest contacts information and their conversation details
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER']">
          <div
            class="bookmark bookmark_dh"
            @click="go('manage_intents.index')"
          >
            <div class="bookmark--title">create intents</div>
            <div class="bookmark--description">
              Add new intents and training phrases to customer conversations
            </div>
          </div>
        </Authorize>
      </div>
    </div>
    <div class="mt-12" v-if="hasSreqPermission('SRV') || hasMSGSPermission || hasLOCSPermission || hasAnyCbrAccess">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_bm">physical property lifecycle</h2>
      <div class="bookmarks-wrapper">
        <div
          class="bookmark bookmark_bm"
          @click="go('sreq.index')"
        >
          <div class="bookmark--title">service requests</div>
          <div class="bookmark--description">
            Manage service requests
          </div>
        </div>

        <div
            v-if="hasSreqPermission('CSV')"
            class="bookmark bookmark_bm"
            @click="go('sreq.settings.community')"
        >
          <div class="bookmark--title">service requests settings</div>
          <div class="bookmark--description">
            Manage service requests settings
          </div>
        </div>

        <div
            v-if="hasMSGSPermission"
            class="bookmark bookmark_bm"
            @click="go('msgs.index')"
        >
          <div class="bookmark--title">resident messaging</div>
          <div class="bookmark--description">Manage resident notifications</div>
        </div>

        <!--<div
            v-if="hasLOCSPermission"
            class="bookmark bookmark_bm"
            @click="go('locations.properties.index')"
        >
          <div class="bookmark--title">location management</div>
          <div class="bookmark--description">Manage locations</div>
        </div>-->

        <div v-if="hasCBRPermission('LSV')" class="bookmark bookmark_bm" @click="go('cbr.living.buildings')">
          <div class="bookmark--title">living spaces</div>
          <div class="bookmark--description">
            Manage living spaces
          </div>
        </div>
        <div v-if="hasCBRPermission('CSV')" class="bookmark bookmark_bm" @click="go('cbr.common')">
          <div class="bookmark--title">common spaces</div>
          <div class="bookmark--description">
            Manage common spaces
          </div>
        </div>
        <div v-if="hasCBRPermission('MSV')" class="bookmark bookmark_bm" @click="go('cbr.maintenance')">
          <div class="bookmark--title">maintenance spaces</div>
          <div class="bookmark--description">
            Manage maintenance spaces
          </div>
        </div>
        <div v-if="hasCBRPermission('LV')" class="bookmark bookmark_bm" @click="go('cbr.designation')">
          <div class="bookmark--title">space designation</div>
          <div class="bookmark--description">
            Designate space purpose
          </div>
        </div>

        <div v-if="hasCBRPermission('UTV')" class="bookmark bookmark_fput" @click="go('cbr.unittypes')">
          <div class="bookmark--title">unit types</div>
          <div class="bookmark--description">
            Manage unit types
          </div>
        </div>
        <div v-if="hasCBRPermission('FPV')" class="bookmark bookmark_fput" @click="go('cbr.floorplans')">
          <div class="bookmark--title">floor plans</div>
          <div class="bookmark--description">
            Manage floor plans
          </div>
        </div>
        <div v-if="hasCBRPermission('UTA')" class="bookmark bookmark_fput" @click="go('cbr.unittypes.assign')">
          <div class="bookmark--title">unit type assignment</div>
          <div class="bookmark--description">
            Assign unit types to units
          </div>
        </div>
      </div>
    </div>

    <div class="mt-12" v-if="hasAnyCommunitySettingsAccess">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_sys">community settings</h2>
      <div class="bookmarks-wrapper">
        <div class="bookmark bookmark_sys" @click="go('community.settings.index')">
          <div class="bookmark--title">community settings</div>
          <div class="bookmark--description">Manage community settings</div>
        </div>
      </div>
    </div>

    <div class="mt-12" v-if="hasLeasesPermission">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_bm">
        resident lifecycle
      </h2>
      <div class="bookmarks-wrapper">
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
              v-if="hasLeasesPermission"
              class="bookmark bookmark_bm"
              @click="go('lease.index')"
          >
            <div class="bookmark--title">default leases</div>
            <div class="bookmark--description">Manage default leases</div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasLeasesPermission"
            class="bookmark bookmark_bm"
            @click="go('mvp_lease_editor.index')"
          >
            <div class="bookmark--title">lease editor</div>
            <div class="bookmark--description">
              manage lease
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasLeasesPermission"
            class="bookmark bookmark_bm"
            @click="go('manage_application.index')"
          >
            <div class="bookmark--title">applications</div>
            <div class="bookmark--description">manage applications</div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasPartnerDocPermission"
            class="bookmark bookmark_bm"
            @click="go('manage-partner-documents.index')"
          >
            <div class="bookmark--title">partner documents</div>
            <div class="bookmark--description">manage partner documents</div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasLeasesPermission"
            class="bookmark bookmark_bm"
            @click="go('leases.index')"
          >
            <div class="bookmark--title">process move-ins</div>
            <div class="bookmark--description">
              ensure all newly signed leases are set up correctly in quext
            </div>
          </div>
        </Authorize>
         <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasLeasesPermission"
            class="bookmark bookmark_bm"
            @click="go('renewals.index')"
          >
            <div class="bookmark--title">renewal offers</div>
              <div class="bookmark--description">
                see upcoming lease endings, send offer letters, and initiate renewals
              </div>
            </div>
         </Authorize>
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasLeasesPermission"
            class="bookmark bookmark_bm"
            @click="go('transfer_resident.index')"
          >
            <div class="bookmark--title">transfer resident</div>
            <div class="bookmark--description">
              move a current resident from one unit to another, usually mid-lease
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasLeasesPermission"
            class="bookmark bookmark_bm"
            @click="go('manage_residents.index')"
          >
            <div class="bookmark--title">resident details</div>
            <div class="bookmark--description">
              this is the manage residents card
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasLeasesPermission"
            class="bookmark bookmark_bm"
          >
            <div class="bookmark--title">move out residents</div>
            <div class="bookmark--description">
              this is process for move-out
            </div>
          </div>
        </Authorize>
        <Authorize :has-role="['SUPER_ADMIN']">
          <div
            v-if="hasLeasesPermission"
            class="bookmark bookmark_bm"
          >
            <div class="bookmark--title">notice to vacant</div>
            <div class="bookmark--description">
              this is the process of notice to vacant
            </div>
          </div>
        </Authorize>
      </div>
    </div>

    <!-- Quext Setup -->
    <div class="mt-12" v-if="hasAuthPermission('QUEXT_SETUP')">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_sys">quext setup</h2>
      <div class="bookmarks-wrapper">
        <div class="bookmark bookmark_sys" @click="go('quextUsers.index')">
          <div class="bookmark--title">quext users</div>
          <div class="bookmark--description">Add and manage Quext Admins</div>
        </div>
        <div class="bookmark bookmark_sys" @click="go('customers.index')">
          <div class="bookmark--title">customers</div>
          <div class="bookmark--description">Add and manage Customers</div>
        </div>
        <div class="bookmark bookmark_sys" @click="go('applications.index')">
          <div class="bookmark--title">applications</div>
          <div class="bookmark--description">Add and manage Applications</div>
        </div>
        <div
          class="bookmark bookmark_sys"
          v-if="hasAccessTo('IPS')"
          @click="go('integrations.index')"
        >
          <div class="bookmark--title">integration partners</div>
          <div class="bookmark--description">
            Add and manage integration partners
          </div>
        </div>
      </div>
    </div>
    <div class="my-12" v-if="hasAuthPermission('ADMIN')">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_sys">
        administrative setup
      </h2>
      <div class="bookmarks-wrapper">
        <div
          class="bookmark bookmark_sys"
          @click="go('company.index', { customerId })"
        >
          <div class="bookmark--title">company details</div>
          <div class="bookmark--description">
            Company information, authorized applications
          </div>
        </div>
        <div
          class="bookmark bookmark_sys"
          @click="go('company.communities.index', { customerId })"
        >
          <div class="bookmark--title">communities</div>
          <div class="bookmark--description">Manage Communities</div>
        </div>
        <div
          class="bookmark bookmark_sys"
          @click="go('company.admins.index', { customerId })"
        >
          <div class="bookmark--title">admins</div>
          <div class="bookmark--description">View company's Admin users</div>
        </div>
        <div
          class="bookmark bookmark_sys"
          @click="go('company.users.index', { customerId })"
        >
          <div class="bookmark--title">users</div>
          <div class="bookmark--description">View company's users</div>
        </div>
        <div
          v-if="isQuextAdmins"
          class="bookmark bookmark_sys"
          @click="go('company.roles.index', { customerId })"
        >
          <div class="bookmark--title">roles</div>
          <div class="bookmark--description">
            Manage roles in authorized applications
          </div>
        </div>
        <div
          v-if="isQuextAdmins"
          class="bookmark bookmark_sys"
          @click="go('company.permissions.index', { customerId })"
        >
          <div class="bookmark--title">permissions</div>
          <div class="bookmark--description">
            Manage permissions in authorized applications
          </div>
        </div>
      </div>
    </div>
    <div class="my-12" v-if="hasAMNTPermission(['EL_AV', 'EL_AGV', 'CL_AV', 'CL_AGV'])">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_sys">amenities management</h2>
      <div class="bookmarks-wrapper">
        <div class="bookmark bookmark_sys" v-if="hasAMNTPermission('EL_AV')" @click="go('amenities.enterprise.individual.index')">
          <div class="bookmark--title">enterprise amenities</div>
          <div class="bookmark--description">Global Catalog, which contains individual amenities</div>
        </div>

        <div class="bookmark bookmark_sys" v-if="hasAMNTPermission('EL_AGV')" @click="go('amenities.enterprise.groups.index')">
          <div class="bookmark--title">enterprise amenity groups</div>
          <div class="bookmark--description">Global Catalog, which contains amenity groups</div>
        </div>

        <div class="bookmark bookmark_sys" v-if="hasAMNTPermission('CL_AV')" @click="go('amenities.community.individual.index')">
          <div class="bookmark--title">individual amenities</div>
          <div class="bookmark--description">Individual amenities from the Global Catalog assigned to the community</div>
        </div>

        <div class="bookmark bookmark_sys" v-if="hasAMNTPermission('CL_AGV')" @click="go('amenities.community.groups.index')">
          <div class="bookmark--title">amenity groups</div>
          <div class="bookmark--description">Amenity groups from the Global Catalog assigned to the community</div>
        </div>
      </div>
    </div>

    <div class="mt-12" v-if="hasRIPermission('RI_VIEW')">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_sys">rentable items</h2>
      <div class="bookmarks-wrapper">
        <div class="bookmark bookmark_sys" v-if="hasRIPermission('RI_VIEW')" @click="go('ri.index')">
          <div class="bookmark--title">rentable items</div>
          <div class="bookmark--description">Rentable items assigned to the community</div>
        </div>
      </div>
    </div>

      <div class="mt-12" v-if="hasAccessTo('IOT')">
          <h2 class="font-frank text-2xl font-600 mb-4 cat_sys">iot</h2>
          <div class="bookmarks-wrapper">
              <div class="bookmark bookmark_sys" @click="go('iot.users.index')">
                  <div class="bookmark--title">users</div>
                  <div class="bookmark--description">Manage users</div>
              </div>
          </div>
      </div>

    <div class="mt-12" v-if="hasAccessTo('RAA')">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_sys">
        resident auth dev tools
      </h2>
      <div class="bookmarks-wrapper">
        <div
          class="bookmark bookmark_sys"
          @click="go('raaTools.applications.index')"
        >
          <div class="bookmark--title">applications</div>
          <div class="bookmark--description">
            Add and manage applications and application specific permissions
          </div>
        </div>

        <div
          class="bookmark bookmark_sys"
          @click="go('raaTools.sharedDataKeys.index')"
        >
          <div class="bookmark--title">shared data keys</div>
          <div class="bookmark--description">
            Manage shared authorized data keys
          </div>
        </div>

        <div
          class="bookmark bookmark_sys"
          @click="go('raaTools.relationshipTypes.index')"
        >
          <div class="bookmark--title">relationship types</div>
          <div class="bookmark--description">
            Manage shared authorized data of relationship types
          </div>
        </div>

        <div
          class="bookmark bookmark_sys"
          @click="go('raaTools.profiles.index')"
        >
          <div class="bookmark--title">profiles</div>
          <div class="bookmark--description">
            Manage profiles, persons and all related entities
          </div>
        </div>
      </div>
    </div>

    <div class="mt-12" v-if="hasAccessTo('RAA')">
      <h2 class="font-frank text-2xl font-600 mb-4 cat_sys">
        resident auth local data
      </h2>
      <div class="bookmarks-wrapper">
        <div
          class="bookmark bookmark_sys"
          @click="go('raaTools.persons.index')"
        >
          <div class="bookmark--title">persons</div>
          <div class="bookmark--description">
            Manage persons, contact information and cognito ids
          </div>
        </div>

        <div
          class="bookmark bookmark_sys"
          @click="go('raaTools.locations.index')"
        >
          <div class="bookmark--title">locations</div>
          <div class="bookmark--description">Manage locations</div>
        </div>

        <div class="bookmark bookmark_sys" @click="go('raaTools.leases.index')">
          <div class="bookmark--title">leases</div>
          <div class="bookmark--description">Manage leases</div>
        </div>

        <div
          class="bookmark bookmark_sys"
          @click="go('raaTools.parties.index')"
        >
          <div class="bookmark--title">parties</div>
          <div class="bookmark--description">Manage parties</div>
        </div>

        <div
          class="bookmark bookmark_sys"
          @click="go('raaTools.memberships.index')"
        >
          <div class="bookmark--title">memberships</div>
          <div class="bookmark--description">Manage memberships</div>
        </div>
      </div>
    </div>

    <homepage-bottom-toolbar></homepage-bottom-toolbar>
  </div>
</template>

<script>
import HomepageBottomToolbar from "@/components/layout/HomepageBottomToolbar";
import AuthMixin from "@/components/auth/AuthMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import SreqMixin from "@/mixins/SreqMixin";
import Authorize from "@/components/auth/Authorize";
import { mapActions, mapGetters } from "vuex";
import {hasAnyCommunitySettingsAccess} from "@/community_settings";

export default {
  components: { HomepageBottomToolbar, Authorize },
  data() {
    return {
      websiteIdbyCommunity: null,
    };
  },
  mixins: [AuthMixin, NotifyMixin, SreqMixin],
  methods: {
    ...mapActions({
        clearWebsiteInformation: 'website_info/clearWebsiteInformation',
    }),    
    go(routeName, params) {
      this.$router.push({ name: routeName, params });
    },
    hasWebsiteV2() {
      this.$cmsDataProvider.get('websiteIdbyCommunity', {communityId: this.community.id})
      .then((response) => {
        if(response.status === 'ok'){
          this.websiteIdbyCommunity = response.message
        }else{
          if(response.status === 'empty'){
            this.websiteIdbyCommunity = null
          }
          if(response.status === 'error'){
            this.notifyError('There is an error in the API: websiteIdbyCommunity.');
            this.websiteIdbyCommunity = null
          }
        }
      }).catch(() => {
        this.notifyError('There was an error getting the website ');
        this.websiteIdbyCommunity = null
      });
    }
  },
  computed: {
    ...mapGetters({
      hasMSGSPermission: 'msgs/hasPermission',
      hasAMNTPermission: 'amnt/hasPermission',
      hasLOCSPermission: 'locations/hasPermission',
      hasCBRPermission: 'cbr/hasPermission',
      hasLeasesPermission: 'leases/hasPermission',
      hasRIPermission: 'ri/hasPermission',
      hasMoveInPermission: 'mi_leases/hasPermission',
      getWebsitesByCommunityId: 'websites/getWebsitesByCommunityId',
      hasPartnerDocPermission: 'partner_documents/hasPermission',
      hasCMSPermission: 'cms/hasPermission',
      hasDHPermission: 'dh/hasPermission',
      hasAuthPermission: 'auth/hasPermission',
    }),
    customerId: function () {
      return this.profile.customerId;
    },
    hasWebsite () {
      if(this.community?.id){
        this.hasWebsiteV2()
        return !!this.websiteIdbyCommunity;
      }else{
        return false
      }
    },
    hasAnyCbrAccess() {
      return this.hasCBRPermission('LV') ||
          this.hasCBRPermission('LSV') ||
          this.hasCBRPermission('CSV') ||
          this.hasCBRPermission('MSV') ||
          this.hasCBRPermission('UTV') ||
          this.hasCBRPermission('FPV') ||
          this.hasCBRPermission('UTA');
    },

    hasMarketingPermission() {
      return this.hasCMSPermission || this.hasDHPermission;
    },

    hasAnyCommunitySettingsAccess,
  },
  async mounted() {
    this.clearWebsiteInformation();
  }
}
</script>

<style scoped>
.badge {
  @apply absolute top-1 right-1 py-1 px-2 text-xs;
}

/* .bookmark {
    @apply bg-white w-1/4 border px-4 py-8 relative cursor-pointer;
  } */

.bookmarks-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;
}
.bookmark {
  @apply bg-white px-3 pt-5 pb-3;
  border: 1px solid #ededed;
  border-bottom: solid 5px transparent;
}
.bookmark:hover {
  @apply cursor-pointer;
}

.bookmark_cms {
  border-bottom-color: rgb(216, 179, 22);
}

.bookmark_mg {
  border-bottom-color: rgb(57, 149, 235);
}

.bookmark_dh {
  border-bottom-color: #e561a3;
}

.bookmark_sys {
  border-bottom-color: #2c2c2c;
}

.bookmark_bm {
  border-bottom-color: #b282ff;
}

.bookmark_fput{
  border-bottom-color: #00BAFF;
}

[class*="cat_"] {
  display: flex;
  align-items: center;
}

[class*="cat_"]:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin-right: 0.5rem;
  transform: translateY(2px);
}

.cat_cms:before {
  background-color: rgb(216, 179, 22);
}

.cat_mg:before {
  @apply bg-blue-500;
}

.cat_dh:before {
  background-color: #e561a3;
}

.cat_sys:before {
  background-color: #2c2c2c;
}

.cat_bm:before {
  background-color: #b282ff;
}

.cat_fput:before{
  background-color: #00BAFF;
}

.bookmark--title {
  @apply font-frank font-600 text-base mb-2 px-3;
}

.bookmark--description {
  @apply font-inter text-sm font-500 px-3 pb-3;
}
</style>
